<template>
  <!--
    The view for the PhotoPont
    Used to do a test Photo
  -->
  <div class="installationDetail_Photopoint">
    <template>
      <div class="card">
        <Portlet
          title="Photopoint"
          icon="camera"
        >
          <template slot="buttons">
            <button 
              class="btn btn-primary float-right mt-2" 
              @click="takeTestPhoto()"
            >
              <font-awesome-icon
                class="mr-2"
                icon="camera"
              />
              <span>Take Test Photo</span>
            </button>
            <div class="clearfix" />
          </template>
          <PhotoPointTestPicture
            ref="photoPointTestPicture"
            :installation-id="installationId"
          />
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailPhotopoint",
  components: {
    PhotoPointTestPicture: () => import('@/components/PhotoPoint/PhotoPointTestPicture.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
    }
  },
  metaInfo () {
    return {
      title: 'Photopoint'
    }
  },
  methods: {
    takeTestPhoto () {
      this.$refs.photoPointTestPicture.takeTestPhoto();
    }
  }
}
</script>